import { Link, navigate } from "gatsby";
import React, { Component, PureComponent } from "react";
import { renderHtml, renderText, validateHTML } from "utils/renderHelpers";
import resolveLinkById, { getSlugByUID } from "utils/resolveLinkById";

import { Books } from "slices/Finalists";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Dialog from "react-a11y-dialog";
import DialogCarousel from "components/DialogCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import GlobalEmitter from "utils/GlobalEmitter";
import Helmet from "react-helmet";
import Route from "route-parser";
import Section from "react-bulma-components/lib/components/section";
import VideoCarousel from "components/VidCarousel";
import Waypoint from "react-waypoint";
import Winner from "slices/Winner";
import classNames from "classnames/bind";
import events from "utils/events";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { firstBy } from "thenby";
import getStartEndDate from "utils/getStartEndDate";
import { graphql } from "gatsby";
import querystring from "querystring";
import { renderFluidImage } from "utils/imageHelpers";
import slugify from "slugify";
import styles from "./AuthorPage.module.scss";
import uniqId from "utils/uid";

// Slices

// graphql

const cx = classNames.bind(styles);

let suppressDialog = false;

const setSuppressDialog = (bool) => {
  suppressDialog = bool === true;
};

const getVideos = (flist, programs) => {
  let list = [];
  if (flist && flist.length) {
    flist.forEach((a) => {
      if (!a.award_link || !a.award_link.raw) return;
      if (a.youtube_embed && a.youtube_embed.embed_url) {
        list.push({ year: parseInt(a.year), embed: a.youtube_embed });
      }
    });
  }
  if (programs && programs.length) {
    programs.forEach((p) => {
      if (!p.program) {
        return;
      }
      if (p.embed && p.embed.provider_name === "YouTube" && p.embed.embed_url) {
        list.push({ year: parseInt(p.year), embed: p.embed });
      }
    });
  }
  if (list.length) {
    list.sort((a, b) => {
      return b.year - a.year;
    });
  }
  return list.map((item) => item.embed);
};

const getAwardsByYear = (flist, slist) => {
  let list = [];
  if (flist && flist.length) {
    flist.forEach((a) => {
      if (!a.award_link || !a.award_link.raw) return;
      const awardData = resolveLinkById(a.award_link.raw.id);
      const isProgram = awardData.slug.indexOf("program") >= 0;
      if (isProgram) {
        return;
      }
      let awardName = awardData.title;
      let year = a.year;
      let book = a.book;

      list.push({
        uid: awardData.uid,
        year,
        winner: a.won === "yes",
        heading: `${year} ${a.won === "yes" ? "Winner" : "Finalist"}`,
        awardName,
        customLabel: awardData.custom_label || null,
        desc: book
          ? renderText(
              book.document[0].data.title.text,
              "span",
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              true,
              book.document[0].data.short_story === "true"
            )
          : null,
        book: book ? book.document[0].data : null,
        citation:
          a.citation && a.citation.text && a.citation.text !== ""
            ? a.citation
            : a.finalist_blurb &&
              a.finalist_blurb.text &&
              a.finalist_blurb.text !== ""
            ? a.finalist_blurb
            : null,
      });
    });
  }
  if (slist && slist.length) {
    slist.forEach((sworks) => {
      if (
        !sworks.primary ||
        !sworks.primary.award ||
        !sworks.primary.award.raw
      ) {
        return;
      }
      let programData = resolveLinkById(sworks.primary.award.raw.id);
      const isProgram = programData.slug.indexOf("program") >= 0;
      if (isProgram) {
        return;
      }
      let year = sworks.primary.year;
      let existing = list.filter((a) => a.uid === programData.uid)[0];
      if (existing) {
        existing.book = sworks.items;
      } else {
        if (programData) {
          list.push({
            year,
            winner: true,
            heading: `${year} Winner`,
            awardName: programData.title,
            uid: programData.uid,
            book: sworks.items,
          });
        }
      }
    });
  }

  list.sort(
    firstBy((a, b) => {
      return parseInt(b.year) - parseInt(a.year);
    })
      .thenBy((a, b) => {
        const aname = a.awardName.toLowerCase();
        const bname = b.awardName.toLowerCase();
        if (aname > bname) {
          return 1;
        }
        if (bname > aname) {
          return -1;
        }
        return 0;
      })
      .thenBy((a) => {
        return a.winner ? -1 : 0;
      })
  );
  return list;
};

const getJurySelections = (jury) => {
  let list = [];
  if (jury && jury.length) {
    jury.forEach((j) => {
      if (!j || !j.award) {
        return;
      }
      list.push({
        year: j.year,
        uid: j.award.document[0].uid,
        title: j.award.document[0].data.title.text,
      });
    });
  }
  return list;
};

const getSelections = (selections) => {
  let result = {};

  if (!selections) {
    return {};
  }
  selections.group.forEach((g) => {
    result[g.programId] = g.nodes.map((n) => ({
      uid: n.uid,
      slug: n.slug,
      firstName: n.data.first_name.text,
      lastName: n.data.last_name.text,
      year: n.data.programs[0].year,
    }));
  });

  return result;
};

const getOtherHonours = (programs, slist, selections) => {
  const list = [];
  if (programs && programs.length) {
    programs.forEach((p) => {
      if (!p.program) {
        return;
      }
      let programData = resolveLinkById(p.program.raw.id);
      let uid = programData.uid;
      if (
        uid.indexOf("fellowship") >= 0 ||
        uid.indexOf("mentorship") >= 0 ||
        uid.indexOf("rising-stars") >= 0
      ) {
        let awardName = programData.title;
        let year = p.year;
        let book = p.book;

        list.push({
          uid: programData.uid,
          title: programData.title,
          selectedBy: p?.selected_by?.document?.[0] || null,
          year,
          winner: p.won === "yes",
          heading: `${year} ${p.won === "yes" ? "Winner" : "Finalist"}`,
          awardName,
          customLabel: p.custom_label || null,
          desc: book
            ? renderText(
                book.document[0].data.title.text,
                "span",
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                true,
                book.document[0].data.short_story === "true"
              )
            : null,
          book: book ? book.document[0].data : null,
          citation:
            p.citation && p.citation.text && p.citation.text !== ""
              ? p.citation
              : p.finalist_blurb &&
                p.finalist_blurb.text &&
                p.finalist_blurb.text !== ""
              ? p.finalist_blurb
              : null,
          selections:
            selections?.[programData.uid]?.filter((s) => s.year === year) ||
            null,
        });
      } else {
        let residencyDuration = getStartEndDate(p.start_date, p.end_date);
        list.push({
          year: p.year,
          uid: programData.uid,
          title: programData.title,
          customLabel: p.custom_label || null,
          residencyDuration,
        });
      }
    });
  }

  if (slist && slist.length) {
    slist.forEach((sworks) => {
      if (
        !sworks.primary ||
        !sworks.primary.award ||
        !sworks.primary.award.raw
      ) {
        return;
      }
      let programData = resolveLinkById(sworks.primary.award.raw.id);
      const isProgram = programData.slug.indexOf("program") >= 0;
      if (!isProgram) {
        return;
      }
      let year = sworks.primary.year;
      let existing = list.filter((a) => a.uid === programData.uid)[0];

      if (existing) {
        existing.book = sworks.items;
      } else {
        if (programData) {
          list.push({
            year,
            winner: true,
            heading: `${year} Winner`,
            awardName: programData.title,
            uid: programData.uid,
            book: sworks.items,
          });
        }
      }
    });
  }

  list.sort(
    firstBy((a, b) => {
      return parseInt(b.year) - parseInt(a.year);
    }).thenBy((a, b) => {
      const aname = a.title.toLowerCase();
      const bname = b.title.toLowerCase();
      if (aname > bname) {
        return 1;
      }
      if (bname > aname) {
        return -1;
      }
      return 0;
    })
  );

  return list;
};

const getBookDialogContent = (stateObj, baseSlug, url, books) => {
  if (!books) {
    return null;
  }
  stateObj.dialogContents = (
    <DialogCarousel
      items={books.map((book, idx) => {
        return (
          <BookCarouselItemRenderer
            key={`book-${idx}`}
            book={book}
            setSuppressDialog={setSuppressDialog}
          />
        );
      })}
      urls={books.map((book) => {
        return slugify(`${book.data.title.text.toLowerCase()}}`);
      })}
      url={url}
      baseUrl={baseSlug}
    />
  );
  return stateObj;
};

const BookCarouselItemRenderer = ({ book }) => {
  const { title, cover_image, description } = book.data;
  const hasDescription = validateHTML(description);
  return (
    <Container className={cx({ modal: true, withDesc: hasDescription })}>
      <Columns mobile centered>
        <Columns.Column mobile={{ size: 11 }} tablet={{ size: 10 }}>
          <Columns mobile multiline>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {renderText(
                title,
                `h2`,
                `has-family-headings is-size-1`,
                { style: { lineHeight: 1.2 } },
                null,
                null,
                null,
                false,
                null,
                true,
                book.data.short_story === "true"
              )}
            </Columns.Column>
            {hasDescription && (
              <Columns.Column
                mobile={{ size: 12 }}
                tablet={{ size: 6 }}
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `center`,
                }}
              >
                <h6
                  className="has-text-weight-bold"
                  style={{ marginBottom: 5 }}
                >
                  Description
                </h6>
                {renderHtml(description, "div")}
              </Columns.Column>
            )}
            <Columns.Column
              mobile={{ size: 12 }}
              tablet={{
                size: hasDescription ? 5 : 12,
                offset: hasDescription ? 1 : null,
              }}
              style={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
              }}
            >
              <figure style={{ width: 275, display: `inline-block` }}>
                {renderFluidImage(cover_image.carousel || cover_image)}
              </figure>
            </Columns.Column>
          </Columns>
        </Columns.Column>
      </Columns>
    </Container>
  );
};

class SelectedWorksCarousel extends PureComponent {
  static INDEX = 0;

  state = { currentIndex: 0 };

  constructor(props) {
    super(props);
    SelectedWorksCarousel.INDEX++;
    this.id = `selected-works-carousel-${SelectedWorksCarousel.INDEX}`;
    this.useIdleInterval = true;
  }

  nextSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex++;
    if (currentIndex >= this.props.books.length) {
      currentIndex = 0;
    }
    this.setState({ currentIndex });
  };

  prevSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = this.props.books.length - 1;
    }
    this.setState({ currentIndex });
  };

  onEnter = () => {
    if (this.useIdleInterval) {
      this.interval = setInterval(() => {
        this.nextSlide();
      }, 3000);
    }
  };

  onLeave = () => {
    clearInterval(this.interval);
  };

  render() {
    const { currentIndex } = this.state;
    const { books } = this.props;

    return (
      <Columns.Column
        id={this.id}
        key={this.id}
        className={styles.listItem}
        mobile={{ size: 6 }}
        tablet={{ size: 2 }}
      >
        <Waypoint onLeave={this.onLeave} onEnter={this.onEnter}>
          <div>
            <h2>Selected Works</h2>
            {books.length > 1 && (
              <fieldset
                className={styles.controls}
                aria-label="carousel buttons"
                aria-controls={this.id}
              >
                <button
                  aria-label="previous"
                  type="button"
                  onClick={() => {
                    clearInterval(this.interval);
                    this.prevSlide();
                  }}
                  className={cx({
                    carouselButton: true,
                    disabled: currentIndex === 0,
                  })}
                >
                  <i className="icon" />
                  Previous
                </button>
                <button
                  aria-label="next"
                  type="button"
                  onClick={() => {
                    clearInterval(this.interval);
                    this.nextSlide();
                  }}
                  className={cx({
                    carouselButton: true,
                    disabled: this.state.currentIndex === books.length - 1,
                  })}
                >
                  <i className="icon" />
                  Next
                </button>
              </fieldset>
            )}
            {books[currentIndex] &&
              books[currentIndex].book &&
              books[currentIndex].book.document && (
                <React.Fragment>
                  <figure>
                    {renderFluidImage(
                      books[currentIndex].book.document[0].data.image.carousel
                    )}
                  </figure>
                  {renderText(
                    books[currentIndex].book.document[0].data.title,
                    "h3",
                    styles.carouselBookTitle,
                    {},
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                    books[currentIndex].book.document[0].data.short_story ===
                      "true"
                  )}
                </React.Fragment>
              )}
          </div>
        </Waypoint>
      </Columns.Column>
    );
  }
}

class AuthorPage extends Component {
  state = {
    dialogShowing: false,
    dialogTitle: null,
    dialogContents: null,
  };

  static getDerivedStateFromProps(props) {
    if (typeof window === "undefined") {
      return null;
    }
    let authorPageRouteParser = new Route(
      `/authors/${props.data.page.uid}/:path(/:book)(/)`
    );
    const { path, book } = authorPageRouteParser.match(props.location.pathname);

    if (!path) {
      return { dialogShowing: false };
    }

    let state = { dialogShowing: true };

    switch (true) {
      case path === "books":
      case path === "book":
        state.isDialogShowing = true;
        ///stateObj, baseSlug, url, books
        getBookDialogContent(
          state,
          `/authors/${props.data.page.uid}/${path}`,
          book,
          props.pageContext.books.edges.map((edge) => edge.node)
        );
        break;
      default:
        state.dialogShowing = false;
        break;
    }
    return state;
  }

  static getPageMeta(globals, page, location) {
    let title = `${page.first_name.text} ${page.last_name.text}`;
    let description = page.bio.text || {
      text: `Author ${page.first_name.text} ${page.last_name.text}`,
    };
    let img_alt = page.image.alt || { text: title };
    let fb_image = page.image;
    let twitter_image = page.image;

    let pageTitle = `${title} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  checkQueryString() {
    if (!this.props.location) {
      return;
    }
    const qs = querystring.parse(this.props.location.search.substr(1));
    if ((qs && qs.hasOwnProperty("books")) || qs.hasOwnProperty("book")) {
      let key = Object.keys(qs)[0];
      setTimeout(() => {
        navigate(`/${this.props.data.page.slug}/books/${qs[key]}/`, {
          state: { modal: true },
        });
      }, 1000);
    }
  }

  componentDidMount() {
    suppressDialog = false;
    this.positionDialog();
    this.checkQueryString();
    setTimeout(() => {
      GlobalEmitter.on(events.resize, this.onResize);
      this.positionDialog();
      this.checkDialog();
    }, 0);
  }

  componentWillUnmount() {
    this.canOpenDialog = false;
    this.dialogDisabled = true;
    GlobalEmitter.off(events.resize, this.onResize);
  }

  onResize = () => {
    this.positionDialog();
  };

  positionDialog() {
    // if (this.state.dialogContents) {
    //   let dialog = this.dialog ? this.dialog.dialog : null;
    //   if (!dialog) {
    //     return;
    //   }
    //   clearTimeout(this.dialogTimeout);
    //   this.dialogTimeout = setTimeout(() => {
    //     let width = dialog.getBoundingClientRect().width;
    //     let height = dialog.getBoundingClientRect().height;
    //     dialog.style.top = `${Math.round(
    //       window.innerHeight * 0.5 - height * 0.5
    //     )}px`;
    //     dialog.style.left = `${Math.round(
    //       window.innerWidth * 0.5 - width * 0.5
    //     )}px`;
    //   }, 0);
    // }
  }

  onShowDialog = () => {
    document.documentElement.style.overflow = "hidden";
  };

  checkDialog = () => {
    if (!this.canOpenDialog) {
      return;
    }
    if (this.dialog) {
      this.dialog.on("show", this.onShowDialog);
    }
    if (this.dialog && this.state.dialogShowing) {
      this.positionDialog();
      this.dialog.dialog.parentNode
        .querySelector(".dialog-overlay")
        .addEventListener("click", this.hideDialog);
      this.dialog.show();
      this.dialog.on("hide", this.hideDialog);

      this.isDialogShowing = true;
      clearTimeout(this.dialogTimeout);
      this.dialogTimeout = setTimeout(() => {
        this.positionDialog();
      }, 0);
    } else if (
      this.dialog &&
      !this.state.dialogShowing &&
      this.isDialogShowing
    ) {
      this.isDialogShowing = false;
      this.dialog.hide();
    }
  };

  hideDialog = (e) => {
    document.documentElement.style.overflow = "auto";
    if (this.dialog) {
      try {
        this.dialog.dialog.parentNode
          .querySelector(".dialog-overlay")
          .removeEventListener("click", this.hideDialog);
        this.dialog.off("hide", this.hideDialog);
      } catch (e) {}
    }
    this.isDialogShowing = false;
    if (this.dialogDisabled || suppressDialog) {
      return;
    }
    let loc = this.props.location.pathname;
    loc = loc.split("/");
    loc.shift();
    loc = loc[0] + "/" + loc[1];
    navigate(`/${loc}/`, { state: { modal: true } });
  };

  componentDidUpdate() {
    this.checkDialog();
  }

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.canOpenDialog = true;

    this.videos = getVideos(
      props.data.page.data.award_finalist,
      props.data.page.data.programs
    );

    this.awardsByYear = getAwardsByYear(
      props.data.page.data.award_finalist,
      props.data.page.data.selected_works
    );

    this.jurySelections = getJurySelections(props.data.page.data.jury);
    this.selections = getSelections(props.data.selections);

    this.otherHonours = getOtherHonours(
      props.data.page.data.programs,
      props.data.page.data.selected_works,
      this.selections
    );
  }

  render() {
    let { page } = this.props.data;
    const { books, parentPage, globals } = this.props.pageContext;

    this.parentPage = null;
    this.parentPath = null;

    if (
      this.props.location.state &&
      this.props.location.state.redirect === true
    ) {
      this.parentPage = parentPage;
    } else {
      if (!this.parentPage && this.props.prevLocation) {
        if (
          this.props.prevLocation.pathname.indexOf("/programs/") === 0 ||
          this.props.prevLocation.pathname.indexOf("/awards/") === 0 ||
          this.props.prevLocation.pathname.indexOf("/events/") === 0 ||
          this.props.prevLocation.pathname.indexOf("/writers-books") === 0
        ) {
          this.parentPage = this.props.prevPage;
          this.parentPath = this.props.prevLocation.pathname;
        }
      }
    }

    if (!this.parentPage) {
      this.parentPage = parentPage;
    }
    const { dialogTitle, dialogContents } = this.state;
    const { slug } = page;
    const { first_name, last_name } = page.data;
    const hasVideos = this.videos && this.videos.length > 0;
    const hasAwards = this.awardsByYear && this.awardsByYear.length > 0;
    const hasOtherHonours = this.otherHonours && this.otherHonours.length > 0;
    const hasJurySelections =
      this.jurySelections && this.jurySelections.length > 0;

    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class: "page",
          }}
          title={`${first_name.text} ${last_name.text} | Writers' Trust of Canada`}
          meta={AuthorPage.getPageMeta(
            globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        <div className={styles.wrapper}>
          <Container className={styles.backLink}>
            <Link
              to={`/${
                this.parentPath
                  ? this.parentPath.substr(1)
                  : this.parentPage.slug
              }`}
              title={`Back to ${this.parentPage.data.title.text}`}
            >
              {this.parentPage.data.title.text}
            </Link>
          </Container>
          <Winner winner={page} theme="black" isAuthorPage={true} />
          {hasVideos && (
            <VideoCarousel
              theme={"white"}
              heading={"Videos"}
              items={this.videos}
            />
          )}
          {(hasAwards || hasJurySelections) && (
            <Section
              className={`${books ? "grey" : "white"} ${cx({
                awards: true,
                grey: books ? true : false,
                white: books ? false : true,
              })}`}
            >
              <Container>
                <Columns mobile multiline>
                  {this.awardsByYear && this.awardsByYear.length > 0 && (
                    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                      <h4>Award History</h4>
                    </Columns.Column>
                  )}
                  {this.awardsByYear.map(
                    (
                      {
                        heading,
                        awardName,
                        desc,
                        book,
                        citation,
                        uid,
                        ...rest
                      },
                      idx
                    ) => {
                      return (
                        <Columns.Column
                          key={`awardbyyear-${uid}-container-${idx}`}
                          className={styles.listItem}
                          mobile={{ size: 12 }}
                          tablet={{ size: 12 }}
                        >
                          <Columns mobile multiline>
                            <Columns.Column
                              key={`awardbyyear-${uid}-${idx}`}
                              className={styles.listItem}
                              mobile={{ size: 12 }}
                              tablet={{ size: 2 }}
                            >
                              <h2>{heading}</h2>
                              <Link title={awardName} to={`/awards/${uid}`}>
                                {awardName}
                              </Link>
                              {desc && (
                                <React.Fragment>
                                  <br />
                                  <span>for {desc}</span>
                                </React.Fragment>
                              )}
                            </Columns.Column>
                            {citation && (
                              <Columns.Column
                                key={`awardbyyear-${uniqId()}`}
                                className={cx({
                                  listItem: true,
                                  citation: true,
                                })}
                                mobile={{ size: 12 }}
                                tablet={{ size: 7 }}
                              >
                                <h2 className={styles.citationHeading}>
                                  {uid.indexOf("matt-cohen") >= 0
                                    ? "Selection Committee Citation"
                                    : "Jury Citation"}
                                </h2>
                                {renderHtml(citation)}
                              </Columns.Column>
                            )}
                            {book && Array.isArray(book) ? (
                              <SelectedWorksCarousel books={book} />
                            ) : book ? (
                              <Columns.Column
                                key={`awardbyyear-${uniqId()}`}
                                className={styles.listItem}
                                mobile={{ size: 6 }}
                                tablet={{ size: 2 }}
                              >
                                <figure>
                                  {renderFluidImage(book.cover_image.carousel)}
                                </figure>
                              </Columns.Column>
                            ) : null}
                          </Columns>
                        </Columns.Column>
                      );
                    }
                  )}
                </Columns>
                {hasJurySelections && (
                  <Columns
                    mobile
                    multiline
                    style={{ paddingTop: hasAwards ? `2rem` : 0 }}
                  >
                    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                      <h4>Juror History</h4>
                    </Columns.Column>
                    {this.jurySelections.map((item, idx) => {
                      const { title, year, uid } = item;
                      const section =
                        uid.indexOf("fellowship") >= 0 ||
                        uid.indexOf("mentorship") >= 0
                          ? "programs"
                          : "awards";
                      return (
                        <Columns.Column
                          key={`honour-${idx}`}
                          className={styles.listItem}
                          mobile={{ size: 12 }}
                          tablet={{ size: 2 }}
                        >
                          <h2>{year}</h2>
                          <Link
                            to={`/writers-books/${section}/${uid}/${year}`}
                            title={title}
                          >
                            {title}
                          </Link>
                        </Columns.Column>
                      );
                    })}
                  </Columns>
                )}
              </Container>
            </Section>
          )}
          {hasOtherHonours && (
            <Section
              className={`${hasAwards ? `white` : `white`} ${cx({
                honours: true,
                white: true,
              })}`}
            >
              <Container>
                <Columns mobile multiline>
                  <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                    <h4>Program History</h4>
                  </Columns.Column>
                  {this.otherHonours.map((h, idx) => {
                    const {
                      title,
                      year,
                      uid,
                      jury,
                      residencyDuration,
                      book,
                      citation,
                      customLabel,
                      selectedBy,
                      selections,
                      ...rest
                    } = h;
                    let heading = residencyDuration || year;
                    let link = true;
                    if (customLabel && customLabel !== "none") {
                      heading += " " + customLabel;
                    } else {
                      if (uid.indexOf("matt-cohen") >= 0) {
                        heading += " Selection Committee Member";
                      } else if (uid.indexOf("berton") >= 0) {
                        heading = `Writer in Residence<br/><span class="${styles.authorDates}">${heading}</span>`;
                      } else if (
                        uid.indexOf("fellowship") >= 0 ||
                        uid.indexOf("mentorship") >= 0
                      ) {
                        link = true;
                        heading += " Winner";
                      } else if (uid.indexOf("laurence") >= 0) {
                        link = true;
                        heading += " Lecturer";
                      } else if (jury === true) {
                        heading += " Juror";
                      }
                    }

                    return (
                      <React.Fragment key={`other-${idx}`}>
                        <Columns.Column
                          key={`honour-${idx}`}
                          className={styles.listItem}
                          mobile={{ size: 12 }}
                          tablet={{ size: 2 }}
                        >
                          <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                          {link ? (
                            <Link title={title} to={`${getSlugByUID(uid)}`}>
                              {title}
                            </Link>
                          ) : (
                            <h3>{title}</h3>
                          )}
                          {selectedBy && (
                            <>
                              <h3 className={styles.subheading}>
                                {uid.indexOf("mentorship") >= 0
                                  ? "Mentor"
                                  : "Selected by"}
                              </h3>
                              <Link to={`/${selectedBy.slug}`}>
                                {selectedBy?.data?.first_name.text}{" "}
                                {selectedBy?.data?.last_name.text}
                              </Link>
                            </>
                          )}
                          {selections && selections?.length > 0 && (
                            <>
                              <h3 className={styles.subheading}>
                                {uid.indexOf("mentorship") >= 0
                                  ? `Mentee${selections.length > 1 ? `s` : ``}`
                                  : `Selection${
                                      selections.length > 1 ? `s` : ``
                                    }`}
                              </h3>
                              <ul>
                                {selections.map((s) => (
                                  <li>
                                    <Link to={s.slug}>
                                      {s.firstName} {s.lastName}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </Columns.Column>
                        {citation && (
                          <Columns.Column
                            key={`awardbyyear-${uniqId()}`}
                            className={cx({
                              listItem: true,
                              citation: true,
                            })}
                            mobile={{ size: 12 }}
                            tablet={{ size: 7 }}
                          >
                            <h2 className={styles.citationHeading}>
                              {"Citation"}
                            </h2>
                            {renderHtml(citation)}
                          </Columns.Column>
                        )}

                        {book && Array.isArray(book) ? (
                          <SelectedWorksCarousel books={book} />
                        ) : book ? (
                          <Columns.Column
                            key={`awardbyyear-${uniqId()}`}
                            className={styles.listItem}
                            mobile={{ size: 6 }}
                            tablet={{ size: 2 }}
                          >
                            <figure>
                              {renderFluidImage(book.cover_image.carousel)}
                            </figure>
                          </Columns.Column>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </Columns>
              </Container>
            </Section>
          )}
          {books && (
            <Books
              theme="white"
              slug={slug}
              books={books.edges.map((edge) => edge.node)}
            />
          )}
        </div>
        <Footer data={this.props.pageContext.footerData} theme={`grey`} />
        <Dialog
          id="author-page-dialog"
          appRoot="#__gatsby"
          dialogRoot="#dialog-root"
          useDialog={false}
          dialogRef={(dialog) => (this.dialog = dialog)}
          title={dialogTitle || ``}
          closeButtonContent={
            <i className="icon">
              <FontAwesomeIcon icon={faTimes} />
            </i>
          }
          classNames={{
            element: "dialog-content",
            overlay: "dialog-overlay",
            base: "dialog",
            closeButton: "dialog-close",
            title: "is-sr-only",
          }}
        >
          {dialogContents || ``}
        </Dialog>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String) {
    selections: allPrismicWriter(
      filter: {
        data: {
          programs: { elemMatch: { selected_by2: { uid: { eq: $uid } } } }
        }
      }
    ) {
      group(field: data___programs___program___uid) {
        programId: fieldValue
        nodes {
          uid
          uid
          slug
          data {
            first_name {
              text
            }
            last_name {
              text
            }
            programs {
              year
            }
          }
        }
      }
    }
    page: prismicWriter(uid: { eq: $uid }) {
      uid
      slug
      data {
        first_name {
          text
        }
        last_name {
          text
        }
        bio {
          text
          html
        }
        image {
          url
          dimensions {
            width
            height
          }
          carousel {
            url
            dimensions {
              width
              height
            }
          }
        }
        alternate_image {
          url
          dimensions {
            width
            height
          }
        }
        selected_works {
          primary {
            award {
              raw {
                id
                uid
                slug
              }
            }
            year
          }
          items {
            book {
              document {
                slug
                data {
                  title {
                    text
                  }
                  publish_date
                  short_story
                  image: cover_image {
                    url
                    dimensions {
                      width
                      height
                    }
                    carousel {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                  publisher {
                    document {
                      data {
                        name {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        programs {
          year
          start_date(formatString: "MMMM YYYY")
          end_date(formatString: "MMMM YYYY")
          embed {
            provider_name
            version
            embed_url
            title
          }
          program {
            raw {
              id
              uid
              slug
            }
          }
          selected_by: selected_by2 {
            document {
              uid
              slug
              data {
                first_name {
                  text
                }
                last_name {
                  text
                }
                bio {
                  html
                }
                image {
                  url
                  dimensions {
                    width
                    height
                  }
                  carousel {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          citation {
            text
            html
          }
          custom_label
          embed {
            provider_name
            version
            embed_url
            height
            title
          }
        }
        jury {
          year
          award {
            document {
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }
        award_finalist {
          year
          won
          award_link {
            raw {
              uid
              id
              slug
            }
          }
          finalist_blurb {
            text
            html
          }
          citation {
            text
            html
          }
          citation_excerpt {
            text
          }
          citation_source {
            text
          }
          youtube_embed {
            provider_name
            version
            embed_url
            width
            height
            title
          }
          book {
            document {
              id
              data {
                short_story
                title {
                  text
                }
                description {
                  text
                  html
                }
                cover_image {
                  url
                  carousel {
                    url
                  }
                }
                publisher {
                  document {
                    id
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AuthorPage;
