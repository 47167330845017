import React, { PureComponent } from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import Video from 'slices/Video';
import classNames from 'classnames/bind';
import styles from './VideoCarousel.module.scss';
const cx = classNames.bind(styles);

export default class VideoCarousel extends PureComponent {
  static INDEX = 0;
  state = { currentIndex: 0, autoPlay: false };

  constructor(props) {
    super(props);
    VideoCarousel.INDEX++;
    this.id = `video-carousel-${VideoCarousel.INDEX}`;
  }

  nextSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex++;
    if (currentIndex >= this.props.items.length) {
      currentIndex = 0;
    }
    this.setState({ currentIndex });
  };

  prevSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = this.props.items.length - 1;
    }
    this.setState({ currentIndex });
  };

  render() {
    const { theme, items, heading } = this.props;
    const { currentIndex } = this.state;
    // console.log('currentIdx', currentIndex);
    return (
      <Section
        className={`${theme} ${cx({
          section: true,
          [theme]: true,
          videos: true,
          white: true
        })}`}
      >
        <Container>
          <h4>{heading}</h4>
          <Columns
            className={styles.track}
            style={{
              transform: `translate3d(-${currentIndex * 100}%, 0, 0)`
            }}
            mobile
          >
            {items.map((item, idx) => {
              return (
                <Video
                  onPlayVideo={() => {
                    this.setState({ autoPlay: true });
                  }}
                  onStopVideo={() => {
                    this.setState({ autoPlay: false });
                  }}
                  onEndVideo={this.nextSlide}
                  autoPlay={
                    idx === currentIndex && this.state.autoPlay === true
                  }
                  opacity={idx === currentIndex ? 1 : 0.25}
                  stopped={idx !== currentIndex}
                  key={`${this.id}-item-${idx}`}
                  primary={{ embed_url: item }}
                  marginBottom={0}
                  showTitle={true}
                />
              );
            })}
          </Columns>
          {items[currentIndex].title && (
            <h3 className={styles.title}>{items[currentIndex].title}</h3>
          )}
          {items.length > 1 && (
            <fieldset
              className={styles.controls}
              aria-label="carousel buttons"
              aria-controls={this.id}
            >
              <button
                aria-label="previous"
                type="button"
                onClick={this.prevSlide}
                className={cx({
                  carouselButton: true,
                  disabled: currentIndex === 0
                })}
              >
                <i className="icon" />
                Previous
              </button>
              <button
                aria-label="next"
                type="button"
                onClick={this.nextSlide}
                className={cx({
                  carouselButton: true,
                  disabled: this.state.currentIndex === items.length - 1
                })}
              >
                <i className="icon" />
                Next
              </button>
            </fieldset>
          )}
        </Container>
      </Section>
    );
  }
}
